import React, { useEffect, useState } from 'react';
import { Table, Checkbox } from 'antd';
import DIcon from '../Icons/DIcon';
import { useNavigate } from "react-router-dom";

const TechnologyTable = () => {
  const handleCheckboxChange = () => {
    // Handle checkbox change logic here
    // Update the state based on the checkbox state
  };
  const navigate = useNavigate();
  const [service, setService] = useState(null);
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");
    setService(localStorage.getItem("currentService"));

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);


  const columns = [
    {
      title: (<DIcon iconName='filter.png'></DIcon>),
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => <Checkbox checked={record.checked} onChange={handleCheckboxChange} />,
      // Add a class to the column for styling
      className: 'column-border',
      header: { style: { backgroundColor: 'blue', color: 'white' } },
    },
    {
      title: 'Access Connectivity',
      dataIndex: 'connectivityTechnology',
      key: 'connectivityTechnology',
      // Set the background color of the header
      header: { style: { backgroundColor: 'blue', color: 'white' } },
      // Add a class to the column for styling
      className: 'column-border',
    },
    {
      title: 'Processing Enablers',
      dataIndex: 'edgeEnablers',
      key: 'edgeEnablers',
      // Set the background color of the header
      header: { style: { backgroundColor: 'blue', color: 'white' } },
      // Add a class to the column for styling
      className: 'column-border',
    },
    {
      title: 'Internet connectivity',
      dataIndex: 'ictInfrastructure',
      key: 'ictInfrastructure',
      // Set the background color of the header
      header: { style: { backgroundColor: 'blue', color: 'white' } },
      // Add a class to the column for styling
      className: 'column-border',
    },
    {
      title: 'End Devices',
      dataIndex: 'devices',
      key: 'devices',
      // Set the background color of the header
      header: { style: { backgroundColor: 'blue', color: 'white' } },
      // Add a class to the column for styling
      className: 'column-border',
    },
  ];

  const data2 = [
    {
      id: 1,
      connectivityTechnology: '5G (public)',
      edgeEnablers: "12 x Extreme Edge Raspbery Pi 4, Private Cloud Intel NUC 9 Pro",
      ictInfrastructure: '5G public',
      checked: false,
      devices: '12 Robot Devices'
    },
    {
      id: 2,
      connectivityTechnology: '5G (public)',
      edgeEnablers: 'Public cloud',
      ictInfrastructure: '5G (public)',
      checked: false,
      devices: '12 Robot Devices'
    },
    {
      id: 2,
      connectivityTechnology: '4G (public)',
      edgeEnablers: 'Public cloud',
      ictInfrastructure: '4G public',
      checked: false,
      devices: '12 Robot Devices'
    },
    // Add more data as needed
  ];

  const data1 = [
    {
      id: 1,
      connectivityTechnology: '5G (private)',
      edgeEnablers: '2 x 5G small cells, 1 x Near Edge Jetson (AGX) Xavier (5G Core + Service)',
      ictInfrastructure: 'Fixed Access',
      devices: '2 Drones',
      checked: false,
    },
    {
      id: 2,
      connectivityTechnology: '5G (private)',
      edgeEnablers: '3 x Wi-Fi AP, 1 x Near Edge Jetson (AGX) Xavier (5G Core + Service)',
      ictInfrastructure: 'Fixed Access',
      devices: '2 Drones',
      checked: false,
    },
    {
      id: 3,
      connectivityTechnology: '4G (private)',
      edgeEnablers: '2 x 4G small cells, 3 x Extreme Edge JetsonNano 2 GB Developer Kit, 1 x Near Edge Intel NUC 9 Pro (4G Core)',
      ictInfrastructure: 'Fixed Access',
      devices: '2 Drones',
      checked: false,
    },
    // Add more data as needed
  ];



  const data3 = [
    {
      id: 1,
      connectivityTechnology: 'LoRA Gateway',
      edgeEnablers: '1 x Near Edge NVIDIA (Jetson AGX) Xavier with GPU (Service + Core)',
      ictInfrastructure: '5G public',
      devices: '1 Camera : 40 sensors',
      checked: false,
    },
    {
      id: 2,
      connectivityTechnology: 'NB-IoT',
      edgeEnablers: 'Public Cloud ',
      ictInfrastructure: '4G Public (NB-IoT)',
      devices: '1 Camera : 40 sensors',
      checked: false,
    },
    {
      id: 3,
      connectivityTechnology: 'LoRA Gateway',
      edgeEnablers: 'Public Cloud ',
      ictInfrastructure: '5G public',
      devices: '1 Camera : 40 sensors',
      checked: false,
    },
    // Add more data as needed
  ];

  const data4 = [
    {
      id: 3,
      connectivityTechnology: 'LoRA Gateway',
      edgeEnablers: 'Public Cloud',
      ictInfrastructure: '5G public',
      devices: '1 Camera : 40 sensors',
      checked: false,
    },
    {
      id: 4,
      connectivityTechnology: '5G (public)',
      edgeEnablers: '5G public',
      ictInfrastructure: '5G public',
      devices: '1 Drone',
      checked: false,
    },
    {
      id: 5,
      connectivityTechnology: '4G (public)',
      edgeEnablers: '1 x Extreme Edge JetsonNano 2 GB Developer Kit',
      ictInfrastructure: '4G Public',
      devices: '1 Drone',
      checked: false,
    },
    {
      id: 6,
      connectivityTechnology: '5G Macro (private)',
      edgeEnablers: '1 x Extreme Edge JetsonNano 2 GB Developer Kit, 1 x Near Edge Intel NUC 9 Pro(5G Core)',
      ictInfrastructure: 'Fixed Access',
      devices: '1 Drone',
      checked: false,
    },
  ];

  const data5 = [
    {
      id: 7,
      connectivityTechnology: 'LoRA Gateway',
      edgeEnablers: 'Far Edge Intel NUC 11 Pro',
      ictInfrastructure: 'Satellite IoT',
      devices: '20 Sensors',
      checked: false,
    },
    {
      id: 8,
      connectivityTechnology: 'LoRA Gateway',
      edgeEnablers: 'Public Cloud',
      ictInfrastructure: 'Satellite IoT',
      devices: '20 Sensors',
      checked: false,
    },
    {
      id: 9,
      connectivityTechnology: 'LoRA Gateway',
      edgeEnablers: 'Private Cloud PowerEdge R640',
      ictInfrastructure: 'Satellite IoT',
      devices: '20 Sensors',
      checked: false,
    },
  ];
  const data6 = [
    {
      id: 10,
      connectivityTechnology: 'Public 5G',
      edgeEnablers: '5 x Extreme Edge JetsonNano 2 GB Developer Kit',
      ictInfrastructure: 'Public 5G',
      devices: '5 Cameras',
      checked: false,
    },
    {
      id: 11,
      connectivityTechnology: '5G Macro (private)',
      edgeEnablers: '5 x Extreme Edge JetsonNano 2 GB Developer Kit (Service), 1 x Near Edge Intel NUC 9 Pro(Core)',
      ictInfrastructure: 'Fixed Access',
      devices: '5 Cameras',
      checked: false,
    },
    {
      id: 12,
      connectivityTechnology: '5G Macro (private)',
      edgeEnablers: '1 x Near Edge NVIDIA (Jetson AGX) Xavier with GPU (Service + Core)',
      ictInfrastructure: 'Fixed Access',
      devices: '5 Cameras',
      checked: false,
    },
  ];
  
 
  
  

  
  

  

  const getData = (service) => {
    if (service == 10) return data2;
    if (service == 3) return data3;
    if (service == 9) return data1;
    if (service == 8) return data4;
    if (service == 1) return data5;
    if (service == 7) return data6;
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Table
        dataSource={getData(service)}
        columns={columns}
        rowKey="id"
        pagination={false}
        rowClassName={() => 'table-row'}
        style={{ height: '100%' }}
      />
    </div>
  );
};

export default TechnologyTable;
