import React from 'react';
import './App.css';
import AppRouter from './AppRouter';
import './globals.css';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <div className="App">
      <ConfigProvider
  theme={{
    components: {
      Slider: {
        controlSize:20,
        controlHeight:20
        /* here is your component tokens */
      },
    },
  }}
>
<AppRouter />
</ConfigProvider>
      
     
    </div>
  );
}

export default App;
