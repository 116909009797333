import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";

import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";
import LocationIcon from "../../components/Icons/Location";
import TerrainTypeButton from "../../components/Button/TerrainTypeButton";
import AreaInput from "../../components/Inputs/AreaInput";
import CardText from "../../components/Texts/CardText";
import CountrySelect from "../../components/Inputs/CountrySelect";
import WeatherItem from "../../components/Items/WeatherItem";
import LocationActionRow from "../../components/Items/LocationActionRow";

export default function LocationScreen01() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const [terrainTypeItems] = useState([
    { id: 1, text: "Valley", checked: false, color: "#6C9D60",isActive:true },
    { id: 2, text: "Forest", checked: false, color: "#145216",isActive:true },
    { id: 3, text: "Mountains", checked: false, color: "#774B23",isActive:true },
    {
      id: 4,
      text: "Sea ",
      checked: false,
      color: "#2359AB",isActive:true
    },
    { id: 5, text: "River", checked: false, color: "#239AB5",isActive:true },

    // Add more sector items as needed
  ]);

  const [weatherConditionItems] = useState([
    { id: 1, text: "Sunny", checked: false, color: "#CD9400",isActive:true },
    { id: 2, text: "Foggy / Cloudy", checked: false, color: "#929292",isActive:true },
    { id: 3, text: "Windy", checked: false, color: "#8963C7",isActive:true },
    {
      id: 4,
      text: "Rainy ",
      checked: false,
      color: "#2567E9",
      isActive:true
    },
    { id: 5, text: "Heavy Rain", checked: false, color: "#072B71",isActive:true },

    // Add more sector items as needed
  ]);

  const [sWeatherConditionItems] = useState([
    { id: 1, text: "Heavy Storms", checked: false, color: "#154A56",isActive:true },
    { id: 2, text: "Freezy Temperatures", checked: false, color: "#8DCDD6",isActive:true },
    { id: 3, text: "Severe Winds", checked: false, color: "#0B1742",isActive:true },

    // Add more sector items as needed
  ]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [showC, setShowC] = useState(false);
  const [showFirstRow, setShowFirstRow] = useState(true);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setShowC(true);
    setShowFirstRow(false);
    // Handle any additional logic on country change if needed
  };
  


  const handleConfirmButtonClick = () => {
    
     // navigate(paths.SERVICE.NORMAL_VIEW);
     localStorage.setItem("step03",true);
      navigate(paths.SERVICE.NORMAL_VIEW);
   
   
  };

  return (
    <HomeLayout>
      <AppContent
        width={"90%"}
        title={
          <TitleButton
            icon={<LocationIcon size={"40px"} />}
            buttonText="Location Details"
            isActive={true}
          />
        }
      >
        <Row style={{ width: "80vw" }} gutter={[16, 16]}>
          <Col span={10}>
            {showFirstRow? <Row
              style={{ width: "100%", marginBottom: "30px" }}
              gutter={[16, 16]}
            >
              <Col
                span={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Country</CardText>
                  <div style={{ marginTop: "0px" }}>
                    <CountrySelect  onSelect={handleCountryChange} defaultValue={selectedCountry} />
                  </div>
                </Card>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Area “A” Size</CardText>
                  <div>
                    <AreaInput />
                  </div>
                </Card>
              </Col>
            </Row>:''}
           
           
            <Row style={{ width: "100%" }} gutter={[16, 16]}>
              <Col
                span={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Terrain Type</CardText>
                  <div style={{ marginTop: "0px" }}>
                    {terrainTypeItems.map((item) => (
                      <TerrainTypeButton
                        color={item.color}
                        isActive={item.isActive}
                        key={item.id}
                        // Replace with your icon
                        buttonText={item.text}
                      />
                    ))}
                  </div>
                </Card>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                  <CardText>Typical Weather Conditions</CardText>
                  <div>
                    {weatherConditionItems.map((item) => (
                      <TerrainTypeButton
                        color={item.color}
                        isActive={item.isActive}
                        key={item.id}
                        // Replace with your icon
                        buttonText={item.text}
                      />
                    ))}
                  </div>
                </Card>
              </Col>
            </Row>
            {showC?
             <Row style={{ width: "100%", marginTop: "30px" }} gutter={[16, 16]}>
             <Col
               span={12}
               style={{ display: "flex", flexDirection: "column" }}
             >
               <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                 <div>
                   <div
                     style={{
                       color: "#1D1D1D",
                       fontSize: "20px",
                       fontWeight: "700",
                       marginBottom: "80px",
                     }}
                   >
                     Area “A” Size
                   </div>

                   <AreaInput />
                 </div>
               </Card>
             </Col>
             <Col span={12}>
               <Card style={{ background: "rgba(0, 44, 60, 0.10)" }}>
                 <CardText>Seasonal Weather Conditions</CardText>
                 <div>
                   {sWeatherConditionItems.map((item) => (
                     <TerrainTypeButton
                       color={item.color}
                       isActive={item.isActive}
                       key={item.id}
                       // Replace with your icon
                       buttonText={item.text}
                     />
                   ))}
                 </div>
               </Card>
             </Col>
           </Row>:''}
           
          </Col>
          <Col span={14}>
          <LocationActionRow/>
            <Card
              justify="center"
              align="middle"
              style={{ background: "rgba(0, 58, 44, 0.03)" }}
            >
              <div>
             
              </div>
              <div
              //style={{ justifyContent: "center", alignContent: "center" }}
              >
                
                
             
                <WeatherItem  iconName="rain.png" height={'100px'} width={'150px'} numCols={4} />
                <WeatherItem  iconName="windy.png" height={'100px'} width={'100px'} numCols={4} />
                <WeatherItem  iconName="mountain.png" height={'120px'} width={'250px'} numCols={2}/>
                <WeatherItem  iconName="forest.png" height={'150px'} width={'150px'} numCols={2}/>
              </div>
            </Card>
          </Col>
        </Row>
        <ConfirmButton
          isActive={true}
          handleClick={() => handleConfirmButtonClick()}
        />
      </AppContent>
    </HomeLayout>
  );
}
