import React,{useState,useEffect}  from "react";
import {
  Layout,
  Typography,
  Button,
  Checkbox,
  Row,
  Col,
  Image,
} from "antd";
import { LanguageRadioGroup } from "../../components/Language/LanguageRadioGroup";
import { useNavigate } from 'react-router-dom';


const { Content } = Layout;
const { Title } = Typography;
const languageOptions = [
  { value: 1, label: " ES", imageUrl: "images/languages/es.png" },
  { value: 2, label: " GR", imageUrl: "images/languages/gr.png" },
  { value: 3, label: " DE", imageUrl: "images/languages/de.png" },
  // Add more language options as needed
];



const IntroScreen = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Check if policies are acceptedp
    const policyAccepted = localStorage.getItem('policyAccepted');

    // If policies are not accepted, redirect to the policy acceptance page
    if (policyAccepted) {
      navigate('/home');
    }
  }, [navigate]);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]);
  const [showLanguageRadioGroup, setShowLanguageRadioGroup] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);

  const handleLanguageImageClick = () => {
    setShowLanguageRadioGroup(!showLanguageRadioGroup);
  };
  const handleRadioChange = (e) => {
    const selectedOption = languageOptions.find(option => option.value === e.target.value);
    setSelectedLanguage(selectedOption);
    console.log(`Radio with value ${e.target.value} clicked`);
    // Hide the LanguageRadioGroup when a radio is clicked
    setShowLanguageRadioGroup(false);
  };
  const handlePolicyCheckboxChange = (e) => {
    setPolicyChecked(e.target.checked);
  };

  const handleStartButtonClick = () => {
    if (policyChecked) {
      // Set the policy acceptance status in local storage
    localStorage.setItem('policyAccepted', 'true');
      // Policy is checked, navigate to "/home"
      navigate('/home');
    } else {
      // Policy is not checked, show a message or take appropriate action
      console.log('Policy not checked, please accept the policy');
    }
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundImage: 'url("images/background.png")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Your logo */}
        <img
          src="images/logo.png"
          alt="Logo"
          style={{ width: "450px", height: "356px", marginBottom: "10px" }}
        />

        {/* Title and text */}
        <Typography
          style={{ textAlign: "center", color: "#fff", marginTop: "-60px" }}
        >
          <Title level={2} style={{ color: "#FFF", fontStyle: "italic" }}>
            KNOWLEDGE FACILITATION TOOL
          </Title>
        </Typography>

        {/* Button */}
        <Button
        onClick={handleStartButtonClick}
          type="primary"
          size="large"
          style={{
            width: "200px",
            borderRadius: "4px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.30)",
            background: "#FFF",
            fontWeight: "600",
            marginTop: "20px",
            color: policyChecked ? '#00678A' : "#BFBFBF"
          }}
        >
          START
        </Button>
        {/* Checkbox for policies */}
        <Checkbox
        checked={policyChecked} onChange={handlePolicyCheckboxChange}
          style={{
            marginTop: "10px",
            textAlign: "justify",
            width: "398px",
            color: "#FFF",
            fontSize: "14px",
          }}
        >
          I understand that the generated data will be kept for evaluation and
          research purposes only and that it is fully anonymised.
        </Checkbox>

        <Row gutter={16}>
          <Col className="gutter-row" span={4} style={{ marginRight: "10px" }}>
            <h3
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: "400",
              }}
              onClick={handleLanguageImageClick}
            >
              

              <img
            src={selectedLanguage.imageUrl}
            style={{ width: '25px', height: '15px', cursor: 'pointer', marginRight:'5px'}}
            alt="Language"
          />{'  '}
          {selectedLanguage.label}{' '}
              <Image
                width={50}
                height={50}
                src="images/arrow-drop-down.svg"
                alt="SVG Image"
              />
            </h3>
          </Col>

          {showLanguageRadioGroup && (
        <Col className="gutter-row" span={18}>
          {LanguageRadioGroup(languageOptions,handleRadioChange)}
        </Col>
      )}
         
        </Row>
      </Content>
    </Layout>
  );
};

export default IntroScreen;
