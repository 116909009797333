import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";
import SectorTitleButton from "../../components/Button/SectorTitleButton";
import ConfirmButton from "../../components/Button/ConfirmButton";
import DIcon from "../../components/Icons/DIcon";
import paths from "../../routes/route";
import SectorIcon from "../../components/Icons/SectorIcon";
import LevelAssessmentButton from "../../components/Button/LevelAssessmentButton";
import UserTypeItem from "../../components/Items/UserTypeItem";

const gridStyle = {
  width: "100%",
  textAlign: "center",
  minHeight:'100%'
  //display:'flex',
  //justifyContent:'center',
};
export default function SectorServiceScreen02() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const [userTypeItems, setSectorItems] = useState([
    { id: 1, text: "End-User (e.g., farmer, farmer association, elderly home, SME) ", checked: false, icon: "fish-food.png" ,isActive:true},
    { id: 2, text: "Public Authority", checked: false,isActive:true },
    { id: 3, text: "Internet Service Provider (ISP)", checked: false,isActive:true },
    
    // Add more sector items as needed
  ]);

  
  const handleConfirmButtonClick = ()=>{
    navigate(paths.Location_DETAILS.INDEX);
  }

  const handleItemClick = (id) => {
    setSectorItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
  };
  
  

  return (
    <HomeLayout>
      <AppContent
      width={"90%"}
        title={
            <TitleButton
            icon={<SectorIcon size={"40px"} />}
            buttonText="Sector and service selection"
            isActive={true}
          />
         
        }
      >
        <Row  style={{ width: "80vw" }} gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={
                <SectorTitleButton
                  icon={<DIcon iconName="account-box.svg" size={"40px"} />}
                  buttonText="Select User Type"
                  isActive={true}
                />
              }
            >
              <Card.Grid style={gridStyle}>
                <div
                  
                >
                  <Row >
                    {userTypeItems.map((item) => (
                      <UserTypeItem
                      isActive={item.isActive}
                        key={item.id}
                       // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleItemClick(item.id)}
                      />
                    ))}
                  </Row>
                  <div style={{marginBottom:"70px"}}></div>
                </div>
              </Card.Grid>
            </Card>
          </Col>
          <Col span={12}>
            <Card
            justify="center" align="middle"
              title={
                <SectorTitleButton
                  icon={<DIcon iconName="analytics.svg" size={"40px"} />}
                  buttonText="Select Level of Assessment"
                  isActive={true}
                />
              }
            >
                <Card.Grid style={gridStyle} >
                <div
                  //style={{ justifyContent: "center", alignContent: "center" }}
                >
                   
                  <Row gutter={[16, 16]} justify="center" align="middle">
                  <Col span={24} justify="center" align="middle">
                    <LevelAssessmentButton buttonText="Local" isActive={true}/>
                    <div style={{marginBottom:"20px"}}></div>
                    <LevelAssessmentButton buttonText="Community"/>
                    <div style={{marginBottom:"20px"}}></div>
                    <LevelAssessmentButton buttonText="Regional"/>
                    </Col>
                    
                    

                    
                    
                  </Row>
                </div>
              </Card.Grid>
            </Card>
          </Col>
        </Row>
        <ConfirmButton isActive={true} handleClick={()=>handleConfirmButtonClick()}/>
      </AppContent>
    </HomeLayout>
  );
}
