import React from 'react';
import TerrainTypeButton from "../../components/Button/TerrainTypeButton";
const LocationActionRow = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', }}>
      <TerrainTypeButton
        width={'120px'}
        color={'#008D6B'}
        isActive={true}
        key={1}
        buttonText={"Area"}
      />
      <TerrainTypeButton
        width={'60px'}
        color={'#002C3C61'}
        isActive={true}
        textColor={'#000000'}
        key={2}
        buttonText={"A"}
      />
      <div>
      <TerrainTypeButton
        width={'150px'}
        //height={'45px'}
        color={'rgba(0, 44, 60, 0.10)'}
        isActive={true}
        textColor={'#000'}
        key={3}
        buttonText={"Add Area"}
        icon={'+'}
      />
      </div>
      
    </div>
  );
};

export default LocationActionRow;
