import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";

import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";
import CardText from "../../components/Texts/CardText";
import WeatherItem from "../../components/Items/WeatherItem";
import ServiceIcon from "../../components/Icons/ServiceIcon";
import ServiceItem from "../../components/Items/ServiceItem";
import ServiceCard from "../../components/Cards/ServiceCard";
import ConnectivityCard from "../../components/Cards/ConnectivityCard";
import ViewSwitcher from "../../components/Button/ViewSwitcher";

export default function ServiceRequirementNormalView() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const [currentStep, setCurrentStep] = useState(0);

  const [fastApplicationItems, setFastApplicationItems] = useState([
    {
      id: 1,
      text: "Does your application need a fast processing?",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 2,
      text: "Yes, the data needs to processed quickly (e.g. button press to turn on a device)",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 3,
      text: "Yes, and the data needs to be processed immediately in real time",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 4,
      text: "Not sure",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
  ]);

  const [connectivityItems, setConnectivityItems] = useState([
    {
      id: 1,
      text: "Rate (in Mbps? Or 3 classes of rate? Maybe small (for IoT cases) medium)",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
  ]);
  const [availableConnectivyItems, setAvailableConnectivyItems] = useState([
    {
      id: 1,
      text: "5G",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "4G",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 3,
      text: "3G",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
        id: 4,
        text: "Wi-Fi",
        checked: false,
        icon: "fish-food.png",
        isActive: true,
      },
      {
        id: 5,
        text: "LoRaWAN",
        checked: false,
        icon: "fish-food.png",
        isActive: true,
      },
      {
        id: 6,
        text: "Other",
        checked: false,
        icon: "fish-food.png",
        isActive: true,
      },
  ]);

  const [publicConnectivyItems, setPublicConnectivyItems] = useState([
    {
      id: 1,
      text: "Yes",
      checked: false,
      isActive: true,
    },
    {
      id: 2,
      text: "No",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 3,
      text: "Not Sure",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
   
  ]);

  const [typeApplicationItems, setTypeApplicationItems] = useState([
    {
      id: 1,
      text: "HD video transmission (e.g. surveillance cameras, on-board cameras for vehicles or drones)",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 2,
      text: "4k video transmission (e.g. professional cameras for image processing)",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 3,
      text: "Transmission of sensor data (continuous or periodical transmission of files or sensor reading)",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 4,
      text: "Not sure",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
  ]);

  const [processingItems, setProcessingItems] = useState([
    {
      id: 1,
      text: "Object detection",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 2,
      text: "IoT monitoring",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 3,
      text: "Pose detection or emotion analysis",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    {
      id: 4,
      text: "No processing requirements",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
  ]);

  const [typeDeviceItems, setTypeDeviceItems] = useState([
    {
      id: 1,
      text: "Drones",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    { id: 2, text: "Surveillance cameras", checked: false, isActive: true },
    { id: 3, text: "IoT sensors", checked: false, isActive: true },
    { id: 4, text: "Machinery", checked: false, isActive: true },
    { id: 5, text: "Other (please specify)", checked: false, isActive: true },

    // Add more sector items as needed
  ]);

  const [userTypeItems, setSectorItems] = useState([
    {
      id: 1,
      text: "Drones",
      checked: false,
      icon: "fish-food.png",
      isActive: true,
    },
    { id: 2, text: "Static cameras", checked: false, isActive: true },
    { id: 3, text: "Sensors", checked: false, isActive: true },
    {
      id: 4,
      text: "Smartphones / Tablet / Laptops",
      checked: false,
      isActive: true,
    },
    { id: 5, text: "Other type of device", checked: false, isActive: true },

    // Add more sector items as needed
  ]);

 

  
  const handleItemClick = (id) => {
    setSectorItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
    
      nextStep(4);
    
  };
  const handleTypeDeviceItemClick = (id) => {
    setTypeDeviceItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
    
      nextStep(5);
    
  };
  const handleConnectivityItemClick = (id) => {
    setConnectivityItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
   
  };
  const handleTypeApplicationItemClick = (id) => {
    setTypeApplicationItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
   
      nextStep(3);
    
  };
  const handleProcessingItemClick = (id) => {
    setProcessingItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );

    nextStep(1);
  };

  const handleFastApplicationItemClick = (id) => {
    setFastApplicationItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );

    nextStep(6);
  };

  const handlePublicConnectivityItemClick = (id) => {
    setPublicConnectivyItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );

    //nextStep(5);
  };
  const handleConnexionItemClick = (id) => {
    setAvailableConnectivyItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: item.checked }
      )
    );

    //nextStep(5);
  };


  const [checkedItems, setCheckedItems] = useState({});

 
  const handleViewChange = (view) => {
    // Handle view change logic here
    console.log(`Switched to ${view} view`);
    navigate(paths.SERVICE.EXPERT_VIEW);
  };


  const handleConfirmButtonClick = () => {
    navigate(paths.IMPACT_ASSESSMENT.INDEX);
  };
  const nextStep = (step) => {
    setCurrentStep(step);
  };

  return (
    <HomeLayout>
      <AppContent
        width={"80%"}
        title={
            <div> <TitleButton
            icon={<ServiceIcon size={"40px"} />}
            buttonText="Service Requirements"
            isActive={true}
          /> <ViewSwitcher onViewChange={handleViewChange} view={'normal'} /></div>
        }
      >
        <CardText fontSize={"30px"}>Connectivity: Area A</CardText>
        <Row
          style={{ width: "70vw" }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Col
            span={currentStep == 3 ? 14 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {currentStep ==3 ? (
              <Card style={{ background: "rgba(0, 44, 60, 0.10)", flex: 1 }}>
                <div>
                  <div
                    style={{
                      color: "#00678A",
                      fontSize: "20px",
                      fontWeight: "700",
                      marginBottom: "40px",
                    }}
                  >
                    What type of devices are you employing?
                  </div>

                  <Row>
                    {userTypeItems.map((item) => (
                      <ServiceItem
                        isActive={item.isActive}
                        key={item.id}
                        // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleItemClick(item.id)}
                      />
                    ))}
                  </Row>
                </div>
              </Card>
            ) : (
              ""
            )}
          </Col>
          {currentStep == 1 || currentStep == 2 ? (
            <div>
              <Row style={{ width: "100%" }}>
                <ServiceCard
                  title={"What type of application are you using?"}
                  items={typeApplicationItems}
                  handleItemClick={handleTypeApplicationItemClick}
                />
              </Row>
            </div>
          ) : (
            ""
          )}
          {currentStep == 5 ? (
            <div>
              <Row style={{ width: "100%" }}>
                <ServiceCard
                  title={"Does your application need a fast processing?"}
                  items={fastApplicationItems}
                  handleItemClick={handleFastApplicationItemClick}
                />
              </Row>
            </div>
          ) : (
            ""
          )}
          {currentStep == 0 ? (
            <div>
              <Row style={{ width: "100%" }}>
                <ServiceCard
                  title={"Connectivity:"}
                  items={connectivityItems}
                  handleItemClick={handleConnectivityItemClick}
                />
              </Row>
              <Row style={{ width: "100%", marginTop: "20px" }}>
                <ServiceCard
                  title={"Processing:"}
                  items={processingItems}
                  handleItemClick={handleProcessingItemClick}
                />
              </Row>
            </div>
          ) : (
            ""
          )}
          {currentStep == 4 ? (
            <ServiceCard
              title={"What type of devices are you employing?"}
              items={typeDeviceItems}
              handleItemClick={handleTypeDeviceItemClick}
            />
          ) : (
            ""
          )}
          {currentStep == 6 ? (
            <div>
                 <ServiceCard
              title={
                "Is there any existing public connectivity available in your area?"
              }
              items={publicConnectivyItems}
              handleItemClick={handlePublicConnectivityItemClick}
            />
            <Row style={{marginTop:'30px'}}>
            <ConnectivityCard
              title={
                "Please select the available connectivities:"
              }
              checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
              checkboxColumns={2}
              items={availableConnectivyItems}
              handleItemClick={handleConnexionItemClick}
            />
            </Row>
            </div>
           
          ) : (
            ""
          )}
          {currentStep == 3 ? (
            <Col span={10} style={{ display: "flex", flexDirection: "column" }}>
              <Card
                justify="center"
                align="middle"
                style={{ background: "rgba(0, 58, 44, 0.03)" }}
              >
                <div></div>
                <div
                //style={{ justifyContent: "center", alignContent: "center" }}
                >
                  <WeatherItem
                    iconName="rain.png"
                    height={"80px"}
                    width={"80px"}
                    numCols={4}
                  />
                  <WeatherItem
                    iconName="windy.png"
                    height={"80px"}
                    width={"80px"}
                    numCols={4}
                  />
                  <WeatherItem
                    iconName="mountain.png"
                    height={"60px"}
                    width={"125px"}
                    numCols={2}
                  />
                  <WeatherItem
                    iconName="forest.png"
                    height={"75px"}
                    width={"75px"}
                    numCols={2}
                  />
                </div>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {currentStep <= 5 ? (
          <ConfirmButton
            isActive={currentStep == 4 ? true : false}
            handleClick={() => nextStep(currentStep == 4 ? 5 : currentStep)}
          />
        ) : (
          <ConfirmButton
            isActive={currentStep > 2 ? true : false}
            handleClick={() => handleConfirmButtonClick()}
          />
        )}
      </AppContent>
    </HomeLayout>
  );
}
