import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

const CountrySelect = ({ onSelect, defaultValue }) => {
  return (
    <Select
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={onSelect}
    >
         <Option value="">Select</Option>
      <Option value="Greece">Greece</Option>
      <Option value="Germany">Germany</Option>
      <Option value="France">France</Option>
      <Option value="Belgium">Belgium</Option>
    </Select>
  );
};
CountrySelect.propTypes = {
    onSelect: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
  };
export default CountrySelect;
