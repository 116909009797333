import React, { useEffect } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import paths from "../../routes/route";
import ImpactActionRow from "../../components/Items/ImpactActionRow";
import ComputeResultButton from "../../components/Button/ComputeResultButton";
import EvaluateIcon from "../../components/Icons/EvaluateIcon";
import TitleV2Button from "../../components/Button/TitleV2Button";
import SectorTitleButton from "../../components/Button/SectorTitleButton";
import TechnologyTable from "../../components/Tables/TechnologyTable";

export default function SuggestedTechnology() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const gridStyle = {
    width: "100%",
    textAlign: "center",
    minHeight:'100%'
    //display:'flex',
    //justifyContent:'center',
  };



  const handleConfirmButtonClick = () => {
    navigate(paths.SUMMARY.SUMMARY_RESULTS_PAGE);
  };
  

  return (
    <HomeLayout>
      <AppContent
        width={"80%"}
        title={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:'column' }}>
            <TitleV2Button
              icon={<EvaluateIcon size={"40px"} />}
              buttonText="Results: "
              secondText="Suggested Technology Mixes"
              isActive={true}
            />
            <div style={{marginTop:'20px'}}><ImpactActionRow /></div>
            
          </div>
        }
      >
        <Row
          style={{ width: "70vw",marginTop:'20px' }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
              <Col span={24}>
            <Card
              title={
                <SectorTitleButton
                  backgroundColor={'#008D6B40'}
                  textColor={'#2F2F2F'}
                  buttonText="Connectivity & Edge Enablers"
                  isActive={true}
                />
              }
            >
                
              <Card.Grid style={gridStyle}>
                <div
                  
                >
                  <Row style={{marginTop:'20px'}}>
                   <TechnologyTable/>
                  </Row>
                  <div style={{marginBottom:"70px"}}></div>
                </div>
              </Card.Grid>
            </Card>
          </Col>
          

         
        </Row>
       
          <ComputeResultButton
            isActive={true }
            textButton={'Proceed'}
            width={'20vh'}
            handleClick={() => handleConfirmButtonClick()}
          />
        
      </AppContent>
    </HomeLayout>
  );
}
