import React, { useState } from 'react';
import { Slider, Typography , Row, Col } from 'antd';
import PropTypes from 'prop-types';

const GreenSlider = ({ label, min, max, value, onChange }) => {
  const handleChangeLocal = (newValue) => {
    onChange(newValue);
  };

  const { Text } = Typography;

  return (
    <Col span={12} style={{padding:'20px',marginTop:'10px'}}>
      <Text strong >{`${label}: ${value}%`}</Text>
      <Slider
        min={min}
        max={max}
        value={value}
        controlSize={40}
        onChange={handleChangeLocal}
        style={{
          marginTop:'20px',
            trackStyle: { backgroundColor: 'green', height: '8px' },
            handleStyle: { borderColor: 'green', height: '16px', width: '16px', marginLeft: '-8px', marginTop: '-4px' },
          }}
      />
    </Col>
  );
};
GreenSlider.propTypes = {
    label: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };

const SlidersGroup = ({ items }) => {
  const [values, setValues] = useState(items.map((item) => item.value));

  const handleChange = (index, newValue) => {
    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);
  };

  return (
    
      <Row gutter={16}>
        {items.map((item, index) => (
          <GreenSlider
            key={index}
            label={item.label}
            min={0}
            max={100}
            value={values[index]}
            onChange={(newValue) => handleChange(index, newValue)}
          />
        ))}
      </Row>
   
  );
};

SlidersGroup.propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        min: PropTypes.number, // optional, set default if needed
        max: PropTypes.number, // optional, set default if needed
      })
    ).isRequired,
  };
export default SlidersGroup;
