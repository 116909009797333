import React, { useEffect } from "react";
import AppContent from "../../components/Layouts/AppContent";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";
import paths from "../../routes/route";
import ServiceIcon from "../../components/Icons/ServiceIcon";
import ImpactCard from "../../components/Cards/ImpactCard";
import ImpactActionRow from "../../components/Items/ImpactActionRow";
import ComputeResultButton from "../../components/Button/ComputeResultButton";

export default function ImpactAssessment() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);



  const items = [
    { label: "Techno-economic", value: 50 },
    { label: "Economic", value: 50 },
    { label: "Social", value: 50 },
    { label: "Environmental", value: 50 },
  ];

  const handleConfirmButtonClick = () => {
    navigate(paths.RESULT.SUGGESTED_TECHNOLOGY);
  };
  

  return (
    <HomeLayout>
      <AppContent
        width={"80%"}
        title={
          <div>
            <TitleButton
              icon={<ServiceIcon size={"40px"} />}
              buttonText="Impact Assessment"
              isActive={true}
            />
            <ImpactActionRow />
          </div>
        }
      >
        <Row
          style={{ width: "70vw" }}
          gutter={[16, 16]}
          justify="center"
          align="middle"
        >
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Col>

          <Row style={{ width: "100%" }}>
            <ImpactCard
              title={
                "Please move the sliders to assign weights to each impact assessment below"
              }
              items={items}
            />
          </Row>
        </Row>
       
          <ComputeResultButton
            isActive={true }
            handleClick={() => handleConfirmButtonClick()}
          />
        
      </AppContent>
    </HomeLayout>
  );
}
