import React from 'react';
import { Input } from 'antd';

const AreaInput = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Input
        placeholder="Enter value"
        style={{ width: '100%', marginBottom: '8px' }}
      />
      <div style={{ color: '#1D1D1D', fontSize: '20px',fontWeight:'700' }}>Km<sup>2</sup></div>
    </div>
  );
};

export default AreaInput;
