import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import MenuButton from "../../components/Button/MenuButton";
import SectorIcon from "../../components/Icons/SectorIcon";
import LocationIcon from "../../components/Icons/Location";
import ServiceIcon from "../../components/Icons/ServiceIcon";
import EvaluateIcon from "../../components/Icons/EvaluateIcon";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import paths from "../../routes/route";


export default function HomeScreen() {
  const navigate = useNavigate();
  const [step02,setStep02] = useState(false);
  const [step03,setStep03] = useState(false);
  
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");
    const step02 = localStorage.getItem("step02");

    setStep02(step02);
    setStep03(localStorage.getItem("step02"));
    console.log(step02);

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  

 
  const handleLocationButtonClick = ()=>{
    
   
    navigate(paths.Location_DETAILS.INDEX);

    
  }
  const handleServiceButtonClick = ()=>{
    
    navigate(paths.SERVICE.NORMAL_VIEW);

  }

  return (
    <HomeLayout>
      <AppContent>
        <MenuButton
          icon={<SectorIcon />}
          buttonText="Sector and service selection"
          isActive={true}
          handleClick={() => navigate(paths.SECTOR_SEVICES.INDEX)}
        />
        <MenuButton isActive={step02 != null && step02 ? true : false}
         icon={<LocationIcon />} buttonText="Location Details"
         handleClick={()=>handleLocationButtonClick()} />

        <MenuButton icon={<ServiceIcon />} buttonText="Service Requirements"
        isActive={step03 != null && step03 ? true : false}
        handleClick={()=>handleServiceButtonClick()} />
        <MenuButton
          icon={<EvaluateIcon />}
          buttonText="Evaluate your case"
          height={"120px"}
        />
      </AppContent>
    </HomeLayout>
  );
}
