import React, { useEffect, useState } from "react";
import AppContent from "../../components/Layouts/AppContent";
import SectorIcon from "../../components/Icons/SectorIcon";
import HomeLayout from "../../components/Layouts/HomeLayout";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "antd";
import TitleButton from "../../components/Button/TitleButton";
import SectorTitleButton from "../../components/Button/SectorTitleButton";
import ServiceSelectionIcon from "../../components/Icons/ServiceSelection";
import SectorSelectionIcon from "../../components/Icons/SectorSelectionIcon";
import SectorItem from "../../components/Items/SectorItem";
import ConfirmButton from "../../components/Button/ConfirmButton";
import paths from "../../routes/route";

const gridStyle = {
  width: "100%",
  textAlign: "center",
  //display:'flex',
  //justifyContent:'center',
};
export default function SectorServiceScreen() {
  const navigate = useNavigate();
  useEffect(() => {
    // Check if policies are accepted
    const policyAccepted = localStorage.getItem("policyAccepted");

    // If policies are not accepted, redirect to the policy acceptance page
    if (!policyAccepted) {
      navigate("/");
    }
  }, [navigate]);

  const [selected,setSelected]= useState(false);
  const [selectedSector, setSelectedSector] = useState(null); // Replace with your actual state and setter
  const [selectedService, setSelectedService] = useState(null); // Replace with your actual state and setter
  const [sectorItems, setSectorItems] = useState([
    { id: 1, text: "Aquaculture", checked: false, icon: "fish-food.png" ,isActive:true},
    { id: 2, text: "Agriculture", checked: false, icon: "agriculture.svg",isActive:true },
    { id: 3, text: "Forestry", checked: false, icon: "foresty.png",isActive:true },
    {
      id: 4,
      text: "Environmental monitoring",
      checked: false,
      icon: "leaf.png",
      isActive:true
    },
    { id: 5, text: "eHealth", checked: false, icon: "heart-health.png",isActive:true },
    { id: 6, text: "eGovernance", checked: false, icon: "museum.png" ,isActive:true},
    { id: 7, text: "Tourism", checked: false, icon: "agriculture.svg" ,isActive:true},
    { id: 8, text: "Education", checked: false, icon: "school.png" ,isActive:true},
    // Add more sector items as needed
  ]);

  const [serviceItems, setServiceItems] = useState([
    { id: 1, text: "Water Quality Monitoring", checked: false, icon: "water.png" },
    { id: 2, text: "Remote Farming", checked: false, icon: "agriculture.svg" },
    { id: 3, text: "Precision Farming", checked: false, icon: "barley.png" },
    {
      id: 4,
      text: "Precision Agriculture",
      checked: false,
      icon: "grass.png",
    },
    { id: 5, text: "Farm management", checked: false, icon: "fence.png" },
    { id: 6, text: "Land Use / Land Cover", checked: false, icon: "field.png" },
    { id: 7, text: "Livestock health", checked: false, icon: "agriculture.svg" },
    { id: 8, text: "Forest Management", checked: false, icon: "forest1.png" },
    // Add more sector items as needed
  ]);

// Service 02

  const [service2Items, setService2Items] = useState([
    { id: 9, text: "Drones Operation", checked: false, icon: "grone.svg" ,isActive:false},
    { id: 10, text: "Health Monitoring", checked: false, icon: "medical-doctor.png",isActive:false },
    { id: 11, text: "Government e-Services", checked: false, icon: "department.png",isActive:false },
    {
      id: 12,
      text: "Leisure",
      checked: false,
      icon: "leisure.svg",
    },
    { id: 13, text: "Distance Learning", checked: false, icon: "book-and-pencil.png" },
    { id: 14, text: "Basic Connectivity", checked: false, icon: "public.svg" },
    { id: 15, text: "High data rate services", checked: false, icon: "frame.svg" },
   
    // Add more sector items as needed
  ]);


  // Helper function to determine service ids for a given sector
const getServiceIdsForSector = (sector, serviceItems, service2Items) => {
  switch (sector) {
    case "Agriculture":{
      // Example: Service ids for Agriculture sector from serviceItems and service2Items
      const agricultureServiceIds = [
        ...serviceItems.filter((item) => item.text === "Remote Farming").map((item) => item.id),
        ...serviceItems.filter((item) => item.text === "Precision Farming").map((item) => item.id),
        ...serviceItems.filter((item) => item.text === "Precision Agriculture").map((item) => item.id),
        ...serviceItems.filter((item) => item.text === "Farm management").map((item) => item.id),
        ...serviceItems.filter((item) => item.text === "Livestock health").map((item) => item.id),
        
      ];
      const agricultureService2Ids = [
       ...service2Items.filter((item) => item.text === "Drones Operation").map((item) => item.id),
       ...service2Items.filter((item) => item.text === "Basic Connectivity").map((item) => item.id),
       ...service2Items.filter((item) => item.text === "High data rate services").map((item) => item.id),
        // Add more service ids as needed
      ];
      return [agricultureServiceIds,agricultureService2Ids];}


      case "Aquaculture":{
        // Example: Service ids for Agriculture sector from serviceItems and service2Items
        const agricultureServiceIds = [
          ...serviceItems.filter((item) => item.text === "Remote Farming").map((item) => item.id),
          ...serviceItems.filter((item) => item.text === "Farm management").map((item) => item.id),
          ...serviceItems.filter((item) => item.text === "Water Quality Monitoring").map((item) => item.id),
          
        ];
        const agricultureService2Ids = [
         ...service2Items.filter((item) => item.text === "Basic Connectivity").map((item) => item.id),
         ...service2Items.filter((item) => item.text === "High data rate services").map((item) => item.id),
          // Add more service ids as needed
        ];
        return [agricultureServiceIds,agricultureService2Ids];} 
       
        case "Forestry":{
          // Example: Service ids for Agriculture sector from serviceItems and service2Items
          const agricultureServiceIds = [
            ...serviceItems.filter((item) => item.text === "Forest Management").map((item) => item.id),
            ...serviceItems.filter((item) => item.text === "Land Use / Land Cover").map((item) => item.id),
            
          ];
          const agricultureService2Ids = [
           ...service2Items.filter((item) => item.text === "Basic Connectivity").map((item) => item.id),
           ...service2Items.filter((item) => item.text === "High data rate services").map((item) => item.id),
            // Add more service ids as needed
          ];
          
          return [agricultureServiceIds,agricultureService2Ids];} 

          case "eHealth":{
            // Example: Service ids for Agriculture sector from serviceItems and service2Items
            const agricultureServiceIds = [
             
            ];
            const agricultureService2Ids = [
              ...service2Items.filter((item) => item.text === "Health Monitoring").map((item) => item.id),
             ...service2Items.filter((item) => item.text === "Basic Connectivity").map((item) => item.id),
             ...service2Items.filter((item) => item.text === "High data rate services").map((item) => item.id),
              // Add more service ids as needed
            ];
            
            return [agricultureServiceIds,agricultureService2Ids];}    
    // Add more cases for other sectors
    default:
      return [];
  }
};
  

  const handleItemClick = (id) => {
    setSectorItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );

    const selectedSector = sectorItems.find((item) => item.id === id);
    setSelectedSector(selectedSector.id);

    // Reset all services to inactive state
    setServiceItems((prevItems) =>
      prevItems.map((item) => ({ ...item, checked: false,isActive:false }))
    );
    setService2Items((prevItems) =>
      prevItems.map((item) => ({ ...item, checked: false,isActive:false }))
    );
    setSelected(false);

    // Determine the service ids based on the selected sector
    const [serviceIds,service2Ids] = getServiceIdsForSector(
      selectedSector.text,
      serviceItems,
      service2Items
    );

    // Activate the specific services based on the determined serviceIds
    if (serviceIds && serviceIds.length > 0) {
      setServiceItems((prevItems) =>
        prevItems.map((item) =>
          serviceIds.includes(item.id) ? { ...item, isActive: true } : item
        )
      );
    }
    if (service2Ids && service2Ids.length > 0) {
      console.log(service2Ids);
      setService2Items((prevItems) =>
    prevItems.map((item2) => ({ ...item2, isActive: service2Ids.includes(item2.id) }))
  );
    }

    

    

  };

 
  const handleServiceItemClick = (id) => {
    setServiceItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
    setService2Items((prevItems) =>
    prevItems.map((item) => ({ ...item, checked: false }))
  );
  setSelected(true);
  setSelectedService(id);

  };

  const handleService2ItemClick = (id) => {
    setService2Items((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, checked: !item.checked }
          : { ...item, checked: false }
      )
    );
    setServiceItems((prevItems) =>
    prevItems.map((item) => ({ ...item, checked: false }))
  );

  setSelected(true);
  setSelectedService(id);


  };
  const handleConfirmButtonClick = ()=>{
    localStorage.setItem("step01",true);
    localStorage.setItem("currentSector",selectedSector);
    localStorage.setItem("currentService",selectedService);
    navigate(paths.SECTOR_SEVICES.STEP02);
  }

  return (
    <HomeLayout>
      <AppContent
      width={"90%"}
        title={
          <TitleButton
            icon={<SectorIcon size={"40px"} />}
            buttonText="Sector and service selection"
            isActive={true}
          />
        }
      >
        <Row  style={{ width: "80vw" }} gutter={[16, 16]}>
          <Col span={10}>
            <Card
              title={
                <SectorTitleButton
                  icon={<SectorSelectionIcon />}
                  buttonText="Sector  Selection"
                  isActive={true}
                />
              }
            >
              <Card.Grid style={gridStyle}>
                <div
                  
                >
                  <Row >
                    {sectorItems.map((item) => (
                      <SectorItem
                      isActive={item.isActive}
                        key={item.id}
                        icon={item.icon} // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleItemClick(item.id)}
                      />
                    ))}
                  </Row>
                </div>
              </Card.Grid>
            </Card>
          </Col>
          <Col span={14}>
            <Card
            justify="center" align="middle"
              title={
                <SectorTitleButton
                  icon={<ServiceSelectionIcon />}
                  buttonText="Service  Selection"
                  isActive={true}
                />
              }
            >
                <Card.Grid style={gridStyle} >
                <div
                  //style={{ justifyContent: "center", alignContent: "center" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={12} justify="center" align="middle">
                    {serviceItems.map((item) => (
                      <SectorItem
                      isActive={item.isActive}
                        key={item.id}
                        icon={item.icon} // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleServiceItemClick(item.id)}
                      />
                    ))}
                    </Col>

                    <Col span={12} justify="center" align="middle">
                    {service2Items.map((item) => (
                      <SectorItem
                        key={item.id}
                        isActive={item.isActive}
                        icon={item.icon} // Replace with your icon
                        text={item.text}
                        checked={item.checked}
                        onChange={() => handleService2ItemClick(item.id)}
                      />
                    ))}
                    </Col>
                    
                  </Row>
                </div>
              </Card.Grid>
            </Card>
          </Col>
        </Row>
        <ConfirmButton isActive={selected} handleClick={()=>handleConfirmButtonClick()}/>
      </AppContent>
    </HomeLayout>
  );
}
