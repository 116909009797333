import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import DIcon from "../../components/Icons/DIcon";

const WeatherItem = ({ iconName, height, width,numCols  }) => {
    const colSpan = 24 / numCols;
  return (
    <Row gutter={[16, 16]}>
      {[...Array(numCols)].map((_, index) => (
        <Col key={index} span={colSpan} justify="center" align="middle">
          <DIcon iconName={iconName} height={height} width={width} />
        </Col>
      ))}
    </Row>
  );
};

WeatherItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  numCols: PropTypes.number.isRequired,
};

export default WeatherItem;
